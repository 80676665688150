import React from "react"
import { info } from "./info"

import { JsonView, allExpanded, darkStyles, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import { DeveloperList } from "../../component/DeveloperList";

export const DeveloperPage = () => {

    return(
        <div className="developer">
            <div className="developer-sidebar">
                <div>
                    <ul>
                        {
                            info.map((item, index) => {
                                return(
                                    <DeveloperList 
                                        key={index}  
                                        section={index}
                                        item={item}
                                    />

                                )
                            })
                        }
                    </ul>
                </div>
            </div>

            <div className="developer-space">
                <h2>Быстрый старт</h2>
                <p>
                    API EnJoinSport позволяет обеспечить удобную и быструю работу с вашими пользователями в вебе и на мобильных устройствах.
                    Этот раздел поможет вам настроить передачу данных в ваши web-ресурсы.
                </p>
                <div className="errorkey">
                    <p>
                        <b>Коды ошибок</b>
                    </p>
                    <ul>
                        <li>
                            <b>0</b> - ошибка отсутствует
                        </li>
                        <li>
                            <b>1</b> - ошибка входных данных
                        </li>
                        <li>
                            <b>2</b> - пользователь с данным userID не найден
                        </li>
                        <li>
                            <b>3</b> - страховка с данным mid не находится в статусе ожидания оплаты
                        </li>
                        <li>
                            <b>4</b> - ошибка формирования запроса
                        </li>
                        <li>
                            <b>5</b> - открытая страховка с данным mid не найдена или уже закрыта
                        </li>
                        <li>
                            <b>6</b> - неопознанная ошибка от страховой службы
                        </li>
                        <li>
                            <b>7</b> - страховка с данным mid не найдена
                        </li>
                        <li>
                            <b>8</b> - дата рождения пользователя не указана
                        </li>
                        <li>
                            <b>9</b> - не все поля пользователя заполнены
                        </li>
                        <li>
                            <b>10</b> - не все поля родителя заполнены
                        </li>
                        <li>
                            <b>11</b> - у данного пользователя уже есть страховка ожидающая оплаты
                        </li>
                        <li>
                            <b>12</b> - партнер уже существует
                        </li>
                        <li>
                            <b>13</b> - платеж с данным orderID не существует
                        </li>
                        <li>
                            <b>14</b> - логин или пароль указан неверно
                        </li>
                        <li>
                            <b>15</b> - аккаунт временно заблокирован
                        </li>
                        <li>
                            <b>16</b> - платеж с данным orderID уже существует
                        </li>
                        <li>
                            <b>17</b> - пользователь с данным userID уже существует
                        </li>
                    </ul>
                </div>
                {
                    info.map((item, index) =>{
                        return (
                            <div className="developer-block" id={`${index}`}>
                                <h3>{item.title}</h3>
                                {item.desc}

                                {
                                    item.code ? (
                                        <div className="codeItem">
                                            <h5>Пример тела запроса</h5>
                                            <React.Fragment>
                                                <JsonView data={item.code} shouldExpandNode={allExpanded} style={darkStyles} />
                                            </React.Fragment>
                                        </div>
                                    ):""                  
                                }
                                {
                                    item.response ? (
                                        <div className="codeItem">
                                            <h5>Пример тела ответа</h5>
                                            <React.Fragment>
                                                <JsonView data={item.response} shouldExpandNode={allExpanded} style={darkStyles} />
                                            </React.Fragment>
                                        </div>
                                    ):""
                                }
                                {
                                    item.children.map((item, index_2) => {
                                        return(
                                            <div className="developer-block__child" id={`${index}_${index_2}`}>
                                                <h4>{item.title}</h4>
                                                {item.desc}
                                                {
                                                    item.code ? (
                                                        <div className="codeItem">
                                                            <h5>Пример тела запроса</h5>
                                                            <React.Fragment>
                                                                <JsonView data={item.code} shouldExpandNode={allExpanded} style={darkStyles} />
                                                            </React.Fragment>
                                                        </div>
                                                    ):""                  
                                                }
                                                {
                                                    item.response ? (
                                                        <div className="codeItem">
                                                            <h5>Пример тела ответа</h5>
                                                            <React.Fragment>
                                                                <JsonView data={item.response} shouldExpandNode={allExpanded} style={darkStyles} />
                                                            </React.Fragment>
                                                        </div>
                                                    ):""
                                                }
                                           
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}